<template>
	<div class="main">
    <TheTopbar/>
  
    <button class="btn btn-secondary" @click="goBack()">back <b-badge class="text-dark" v-b-tooltip.hover title="Click to go back to the previous page">i</b-badge></button>
    <slot />
	<div class="main-container">
    <div class="d-flex">
      <div class="content-wrapper">
        <Header></Header>
        <div class="options">
                  
                  <button class="btn btn-main" @click="goToProfile()" v-b-tooltip.hover title="Click to view and edit the profile information of the authorized representative">AUTHORIZED REP</button>
                  <button class="btn btn-main " @click="goToCompany()" v-b-tooltip.hover title="Click to view and edit the profile information of the company">COMPANY</button>
                   <button class="btn btn-main active" @click="goToApplication()" v-b-tooltip.hover title="Click to view and action the application process of the customer">APPLICATION</button>
                  <!-- <button class="btn btn-main" @click="goToUsers()" >USERS</button> -->
                  <button class="btn btn-main" @click="goToTransactions()" v-b-tooltip.hover title="Click to view and monitor the transactions made by the customer">TRANSACTIONS</button>
                  <button class="btn btn-main" @click="goToActivityLogs()"  v-b-tooltip.hover title="Click to view and monitor the activity logs of the customer">ACTIVITY LOGS</button>
          </div>
         <ul class="btn-group-card" >
                  <div class="">
                    <div class="" >
                      <div class="card">
                        <div class="wrapper">
                          <div class="progress-bars">
                            <div>
                              <div class="progress-bar" :class="{ active: state == 'letterOfIntent'}" @click="changeState('letterOfIntent')" v-b-tooltip.hover title="Click to view and action the first step of the application">Letter of Intent</div>
                              <span class="indicator-progress" v-if="isSubmittedStep1"><img :src="require('../assets/circle/current.png')"/></span>
                              <span class="indicator-progress" v-if="isApprovedStep1"><img :src="require('../assets/circle/approved.png')"/></span>
                            </div>
                            <div>
                              <div class="progress-bar" :class="{ active: state == 'busPlan'}" @click="changeState('busPlan')" v-b-tooltip.hover title="Click to view and action the second step of the application">Business Proposal</div>
                              <span class="indicator-progress" v-if="isSubmittedStep2"><img :src="require('../assets/circle/current.png')"/></span>
                              <span class="indicator-progress" v-if="isApprovedStep2"><img :src="require('../assets/circle/approved.png')"/></span>
                            </div>

                            <div>
                              <div class="progress-bar" :class="{ active: state == 'mastersLicensor'}" @click="changeState('mastersLicensor')" v-b-tooltip.hover title="Click to view and action the third step of the application">Master Licensor's Endorsement</div>
                              <span class="indicator-progress" v-if="isSubmittedStep3"><img :src="require('../assets/circle/current.png')"/></span>
                            </div>

                            <div>
                              <div class="progress-bar" :class="{ active: state == 'application'}" @click="changeState('application')" v-b-tooltip.hover title="Click to view and action the fourth step of the application">Corporate Documentation Checklist</div>
                              <span class="indicator-progress" v-if="isSubmittedStep4"><img :src="require('../assets/circle/current.png')"/></span>
                            </div>
                            <div>
                              <div class="progress-bar" :class="{ active: state == 'payment'}" @click="changeState('payment')" v-b-tooltip.hover title="Click to view and action the fifth step of the application">Payment</div>
                              <span class="indicator-progress" v-if="isSubmittedStep5"><img :src="require('../assets/circle/current.png')"/></span>
                            </div>
                            
                            <div>
                              <div class="progress-bar" :class="{ active: state == 'complianceReview'}" @click="changeState('complianceReview')" v-b-tooltip.hover title="Click to view and action the sixth step of the application">Compliance Review</div>
                              <span class="indicator-progress" v-if="isSubmittedStep6"><img :src="require('../assets/circle/current.png')"/></span>
                            </div>
                            <!-- <div>
                              <div class="progress-bar" :class="{ active: state == 'provisionalLicense'}" @click="changeState('provisionalLicense')">Provisional License</div>
                              <span class="indicator-progress" v-if="isSubmittedStep7"><img :src="require('../assets/circle/current.png')"/></span>
                            </div> -->
                            <div>
                              <div class="progress-bar" :class="{ active: state == 'integration'}" @click="changeState('integration')" v-b-tooltip.hover title="Click to view and action the seventh step of the application">Integration</div>
                              <span class="indicator-progress" v-if="isSubmittedStep8"><img :src="require('../assets/circle/current.png')"/></span>
                            </div>
                            <div>
                              <div class="progress-bar" :class="{ active: state == 'fullLicense'}" @click="changeState('fullLicense')" v-b-tooltip.hover title="Click to view and action the last and final step of the application">Full License</div>
                              <span class="indicator-progress" v-if="isSubmittedStep9"><img :src="require('../assets/circle/current.png')"/></span>
                            </div>
                            
                            
                          </div>
                         
                        </div>
                      </div>
                    </div>
                  </div>
                  </ul>
              <div class="modal-body">
              <div>
                <h5 class="text-white">CUSTOMER APPLICATION</h5>
                  <div v-if="state=='letterOfIntent'"><LetterIntent/></div>
                  <div v-if="state=='busPlan'"><BusinessPlan/></div>
                  <div v-if="state=='mastersLicensor'"><MasterLicensor/></div>
                  <div v-if="state=='application'"><ApplicationGaming/></div>
                  <div v-if="state=='payment'"><Payments/></div>
                  <div v-if="state=='complianceReview'"><ComplianceReview/></div>
                  <div v-if="state=='integration'"><Integration/></div>
                  <div v-if="state=='fullLicense'"><FullLicense/></div>


               
               
              </div>
        </div>
        
        <hr>
       <ViewUpload v-if="showUpload" @close="viewUpload(item)" :data="dataInfo"></ViewUpload>
      
      </div> 
    </div>
    </div>
	</div>
  
 
          
</template>
        
<script>
  import moment from 'moment';
  import TheTopbar from '../components/TheTopbar.vue'
  import ViewUpload from '../components/modal/Documents/ViewUpload.vue'
  import Header from '../components/Header.vue'
  import LetterIntent from '../view/LetterIntent.vue'
  import BusinessPlan from '../view/BusinessPlan.vue'
  import MasterLicensor from './MasterLicensor.vue';
  import ApplicationGaming from './ApplicationGaming.vue';
  import Application from '../view/Application.vue'
  import Payments from '../view/Payments.vue'
  import ComplianceReview from '../view/ComplianceReview.vue'
  import Integration from '../view/Integration.vue'
  import FullLicense from '../view/FullLicense.vue'
 
 
  export default {
      name: 'ApplicationGamingTab',
      components: {
        TheTopbar,
        ViewUpload,
        Header,
        LetterIntent,
        BusinessPlan,
        MasterLicensor,
        ApplicationGaming,
        Application,
        Payments,
        ComplianceReview,
        Integration,
        FullLicense

      },
      
       data: function() {
      return { 
          state:'letterOfIntent',
          isEmail: false,
          isMobile: false,
          isEditingEmail: false,
          isEditingMobile: false,
          isSubmittingEmail: false,
          isSubmittingMobile: false,
          isEditingProfile: false,
          isSubmitting: false,
          errorMsg:'',
          birthdate:'',
          birthDate:{
            day: null,
            month: null,
            year: null
          },
         
          isEmail: false,
          isMobile: false,
          birthdate: '',
          formattedBirthdate: '',
          isUnderage: false,
          month:'',
          day:'',
          year:'',
          errorMsg:'',
          address:{
              numberStreet:'',
              cityTown:'',
              provState:'',
              zipcode:''
          },
          showCountryForNationality: false,
          showCountryForAddress: false,
          ncountry:'PH',
          acountry:'PH',
          extended:{
              placeOfBirth: "",
              occupation:"",
              maidenName:"",
              natureOfWork:"",
              sourceOfFunds:"",
              selectedMaritalStatus:"",
              employer:"",
              idNumber:"",
              homePhone: '11111',
              grossMonthly: '',
              nationality:'PH'
          },
          errors: {
          profile: {
              msg: "",
              show: false
          }
          },
          showCountry: false,
          selected: 'mobile',
          country: 'PH',
          mobileCode: '63',
          hideOrShow: "Show",
          list: {
              mobileCodes: []
          },
          isError: false,
          showUpload: false,
          showExitModal: false,
          isEditingProfile: false,
          isSubmitting: false,
          isSubmittingMobile: false,
          isEditingMobile: false,
          isEditingEmail: false,
          isSubmittingEmail: false,
          showPoid: false,
          poid:[],
          poidItem:'',
          showDetails: false,
          poidData:'',
          poidKey:'',
         
          isError: false,
          idTypes:[],
          documentFile:[],
          imgUrl: '/img/logo.fadb0240.png',
          isApproved: false,
          isLoiSubmitted: false,
          selectedPoid: '',
          showUpload: false,
          uploaded: false,
          selected:'',
          isBusPlanSubmitted: false,
          poidKey: '',
          desc:'',
          poids:[],
          isPaid: false,
          isApplicationComplete: false,
          isDueDiligence: false,
          isProvLicense: false,
          isIntegration: false,
          isLicensed: false,
          filename:'',
          isApprovedStep1: false,
          isApprovedStep2: false,
          isApprovedStep3: false,
          isApprovedStep4: false,
          isApprovedStep5: false,
          isApprovedStep6: false,
          isApprovedStep7: false,
          isApprovedStep8: false,
          isApprovedStep9: false,
          loiFile: '',
          busPlanFile:'',
          imgUpload:'',
          showUploadBPlan: false,
          businessFileIdBplan:'',
          businessFileIdLoi:'',
          isLoiApproved: false,
          isBplanApproved: false,
          status: false,
          currentStep:'',
          isSubmittedStep1: false,
          isSubmittedStep2: false,
          isSubmittedStep3: false,
          isSubmittedStep4: false,
          isSubmittedStep5: false,
          isSubmittedStep6: false,
          isSubmittedStep7: false,
          isSubmittedStep8: false,
          isSubmittedStep9: false,
          isApplicationForm: false,
          isProbityForm: false,
          isCompanyProbityForm: false,
          isInvestCommitment: false,
          isAoi: false,
          isCoi: false,
          isGis: false,
          isAfs: false,
          isDirectorsCert: false,
          isITR: false,
          isRules: false,
          isPolicies: false,
          isCPP: false,
          isNameChange: false,
          isShareRegisters: false,
          isBankCert: false,
          isTreasurersAffidavit: false,
          isMtpp: false,
          isGamingCode: false,
          isGamingServiceProvider: false,
          
          
      }
      },
      computed:{
        show() {
          return this.$store.state.show;
        },
        init() {
            return this.$store.state.init;
        },
        

      },
      methods:{
        goHome(){
          window.location.href="/#/customer-search";
        },
        goBack(){
          this.$router.go(-1);
        },
        changeState(state) {
            this.state = state;
            localStorage.setItem("state", state);
          },
        getState() {
            let state = localStorage.getItem("state")
            if(state != "" || state != undefined) {
            this.state = state
            }
        },
        goToUsers(){
          window.location.href="/#/customer-users/" + this.$route.params.entityId
        },
        goToCompany(){
          window.location.href="/#/customer-company/" + this.$route.params.entityId
          },
          goToTransactions(){
          window.location.href="/#/customer-transactions/" + this.$route.params.entityId
        },
        goToApplication(){
          console.log(this.show.profile.signupHost)
          if(this.show.profile.signupHost == 'fintech.ceza.org'){
            window.location.href="/#/customer-application-list/" + this.$route.params.entityId
          }
          if(this.show.profile.signupHost == 'gaming.ceza.org'){
            window.location.href="/#/customer-gaming-list/" + this.$route.params.entityId
          }
         
        },
        goToProfile(){
          window.location.href="/#/customer-profile/" + this.$route.params.entityId
        },
        goToLoi(){
          window.location.href="/#/customer-letter-of-intent/" + this.$route.params.entityId
        },
        goToBusplan(){
          window.location.href="/#/customer-business-plan/" + this.$route.params.entityId
        },
        getPoid(){
          const params = {
            entityId: this.$route.params.entityId
            }
           
            const options = {
              method: 'GET',
              headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                  'vmiadmin-authz': localStorage.getItem("tokenId") 
              }, 
              params,
              url: '/ax/customer/businessfile/' 
              }
              this.axios(options)
              .then((response) => {
                this.idTypes = response.data

                let item = [];
                this.idTypes.forEach((el, i) => {
                  item.push(el);
                  this.imgUrl = el.imageFile
                  
                  
              });
              item.fileType = this.imgUrl.split('.').pop();

              console.log(item.fileType)
               
              }).catch((err) => {
                  
              })
        },
        viewUpload(docData){
          this.dataInfo = docData
          this.showUpload = !this.showUpload
        },
      
        
        
        goToPayment(){
          window.location.href="/#/customer-payments/" + this.$route.params.entityId
        },
        goToCompliance(){
          window.location.href="/#/customer-compliance/" + this.$route.params.entityId
        },
       
       
      toggleCountryListForNationality(){
            this.showCountryForNationality = !this.showCountryForNationality;
          },
          selectCountryForNationality: function(c) {
            this.showCountryForNationality = false;
            this.ncountry = c.countryCode
           
          },
          toggleCountryListForAddress(){
            this.showCountryForAddress = !this.showCountryForAddress;
          },
          selectCountryForAddress: function(c) {
            this.showCountryForAddress = false;
            this.acountry = c.countryCode
           
          },
        openChangePin(){
          this.showChangePin = !this.showChangePin
        },
        //   goBack(){
        //     window.location.href="/#/customer-search";
        // },
          formatDate: function(time){
              return new Date().toDateString();
          },
          goToVerification(){
            window.location.href="/#/verification"
          },
         
          openUpload(){
              this.showUpload = !this.showUpload
          },
          getProfile() {
            const params = {
              eid: this.$route.params.entityId
            }
            const data = Object.keys(params)
                      .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                      .join('&');

            const options = {
              method: 'POST',
              headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                  'vmiadmin-authz': localStorage.getItem("tokenId") 
              }, 
              data,
              url: '/ax/getCustomerProfile',
              }
              this.axios(options)
              .then((response) => {
                console.log(response.data.emailAddress)
                
              this.show.profile = response.data;
              this.show.corpDetails = response.data.corpDetails
              if( this.show.corpDetails.corpInfo != null){
                this.show.corpDetails.corpInfo = response.data.corpDetails.corpInfo
              }
              }).catch((err) => {
                  
              })
          },
       

          viewPoid(){
              this.showPoid = !this.showPoid;
          },
          getPoidType(){
            const options = {
            method: 'GET',
            headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
            url: '/ax/poids',
          }

          this.axios(options)
              .then((response) => {               
             this.poid = response.data
             console.log(response.data)
              }).catch((err) => {
              console.log(err);

              })

          },
          toggleCountryList: function(){
              this.showCountry = !this.showCountry;
              
              },
          
          selectCountry: function(c) {
              this.showCountry = false;
              this.country = c.countryCode;
              this.mobileCode = c.mobileCode;
          },
              
          getMobileCodes: function() {
          this.axios.get('/assets/js/mobilecodes.json')
              .then((response) => {
              this.list.mobileCodes = response.data;
              }).catch((err) => {
              console.log(err);

              })
          },
          isNumber: function(evt) {
              evt = (evt) ? evt : window.event;
              var charCode = (evt.which) ? evt.which : evt.keyCode;
              if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
              evt.preventDefault();
              } else {
              return true;
              }
          },
          cancelEditMobile(){
              this.isEditingMobile = false;
              window.location.reload();
          },
        
          sessionCheck() {
            const options = {
              method: 'POST',
              headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                  'vmiadmin-authz': localStorage.getItem("tokenId") 
              }, 
            
              url: '/ax/sessionCheck',
              }
        this.axios(options)
              .then((response) => {
              
              this.getProfile();
              }).catch((err) => {
                 

                  if(err.response.data.msgCode == '00001') {
                      window.location.href = "/#/login";
                  }  
              })
          },

      },
      mounted(){
          this.sessionCheck();
          this.getPoid();
          this.getState();
          
      }
  }
</script>
        
<style scoped lang="scss">
    * {
    box-sizing: border-box;
    color: white;
  }
  
  /* Create two equal columns that floats next to each other */
  .column {
    float: left;
    width: 50%;
    padding: 10px;
  
  }
  
  /* Clear floats after the columns */
  .row:after {
    content: "";
    display: table;
    clear: both;
  }
  
  /* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
  @media screen and (max-width: 600px) {
    .column {
      width: 100%;
    }
  }
  .checkbox label{
    font-size: 12px !important;
  }
.header-wrapper img{
    width: 10em;
  }
  .main-content{
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    margin: 0 auto !important;
  }
  .wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    margin: 0 auto !important;
    padding: 20px 5px;
    width: 100%;
  }
  .placeholder-circle {
    width: 25px;
    height: 25px;
    border: 1px solid #ccc; /* Gray background */
    border-radius: 50%; /* Makes it a circle */
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 24px;
  }
  .approved-circle{
    width: 25px;
    height: 25px;
    background-color: #c69903; /* Gray background */
    border-radius: 50%; /* Makes it a circle */
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 24px;
  }
  .content-wrapper{
    display: flex;
    flex-direction: column;
    height: auto;
    justify-content: center !important;
    width: 100%;
    min-width: 350px !important;
    margin: 10px auto;
    background-color: #ffffff;
    /* background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important; */
    border-radius: 10px;;
    /* z-index: 2; */
    /* max-height: calc(100vh - 110px);
    overflow-y: auto; */
    padding: 5px;
    
  }
  .progress-bars {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    word-break: break-all;
  }
  .form-group .button{
    margin-top: 100px !important;
  }
  .options button.active{
    background-image: linear-gradient(135deg,#007bff,#007bff 20%,#007bff 70%,#007bff)!important;
    border: 1px #007bff solid !important;
    color: rgb(246, 246, 246) !important;
}
  .icon{
    width: 2rem;
  }
  .progress-bar {
 padding: 20px;
    height: 10px;
    background-color: lightgray;
    margin-right: 5px;
    border-radius: 6px;
  }
  li .active {
    border: 1px solid #07b254 !important;
  }
 
  .enhanced{
    margin-top: 2em;
  }

.three-column {
  display: flex;
  justify-content: space-between;
}
.modal-footer{
  border-top: none !important;
}
.form-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.form-column {
  flex-basis: calc(33.33% - 20px);
  margin-bottom: 20px;
}
form{
  padding-top: 3px;
}

@media (max-width: 767px) {
  .form-column {
    flex-basis: 100%;
  }
}

label {
  color: white;
  margin-right: 5px;
  font-size: 15px;
}
.modal-body{
  margin-left: 15px;
}

   .btn-flag {
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    z-index: 2;
  }
   /* .mobile-container {
    position: relative;
   } */
   .default-flag {
      width: 33px;
      margin-right: 0.25em;
    }
    

    .country-list {
      position: absolute;
      background: #ccc;
      width: 300px;
      color: #333;
      top: 70px;
      padding: 0.5em;
      height: 13em;
      overflow: auto;
      z-index: 3;
    }
      .row {
        margin: 0 !important;
      }
        .col-sm-6 {
          display: flex;
          padding: 0.5em;
          align-items: center;
          cursor: pointer;
        }
        .col-sm-6:hover {
            background: darken(#ccc, 10%);
          }
          .country-name {
            margin-left: 0.5em;
          }

          span {
            font-size: 0.9em;
          }
   

  .flag-list-img {
    width: 33px;
  }
  .btn-cancel{
        float: right;
        background-color: #333;
        color: white;
    }

  .disabled{
  cursor: not-allowed;
}
.input-group{
  flex-wrap: nowrap;
  padding: 0;
}
.input-group .form-control{
  height: auto;
  margin-bottom: 10px;
}
fieldset{
  min-height: 50px !important;
}

.legend{
   color: white !important;
    padding-top: 10px;
  }
  h1 {
  text-align: center;
  font-size: 5rem;
  }

  form {
  width: 90%;
  margin: 0 auto;
  }

  .box {
  padding: 0.1rem;
  border-radius: 6px !important;
  margin-bottom: 10px;
  }

  label {
  display: flex;
  font-size: 16px;;
  }

  input, .form-control, .form-select {
  display: inline-block;
  width: 100%;
  border-radius: 10px;
  }

  input:required:invalid, input:focus:invalid {
  background-position: right top;
  background-repeat: no-repeat;
  -moz-box-shadow: none;
  }

  input:required:valid {
  background-position: right top;
  background-repeat: no-repeat;
  }

  #masthead {
    padding: 10rem 0;
    background-size: 100%;
    /*color: #fff;*/;
  }
  .help{
    margin: 2rem;
    
  }
.progress-bars button.active{
    background-image: linear-gradient(135deg,#007bff,#007bff 20%,#007bff 70%,#007bff)!important;
    border: 1px #007bff solid !important;
    color: rgb(246, 246, 246) !important;
}
@media all and (min-width: 35rem) and (max-width: 60rem) {
 
    .box {
    float: left;
    width: 33%;
    }
    .box2 {
    float: left;
    width: 50%;
    }

    label {
    display: flex;
    }

    #masthead {
    }
}

@media all and (min-width:60rem) {
  
    label {
    display: inline;
    width: 150px;
    float: left;
    }
    .box2 {
    float: left;
    width: 50%;
    }
    .box {
    width: 33%;
    float: left;
    }

    input {
    width: 50%;
    }

    #masthead {
    padding: 9rem;
    }
}




   

.icon img{
  width: 2rem;
}
.card{
  background-image: linear-gradient(
            to bottom,
            #202538 29%,
            #10121C 88%
  );
    box-shadow:0 0 30px rgba(0,0,0,0.3);
    border: 1px solid transparent;
    border-radius: 12px;
    width: 100%;
    height: auto;
    text-align: center;
    color: white;
}


.verified{
  background-color: green;
  color: white;
}
.submitted{
  background-color: green !important;
  color: white;
}
.notSubmitted{
  background-color: red;
  
}
  
 
  
    .progress-bars {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    
    /* margin-bottom: 20px; */
  }
  
  .progress-bar {
    padding: 30px;
    height: 50px;
    background-color: lightgray;
    background-color: #2a4858 !important;
    margin-right: 5px;
    border-radius: 20px !important;
    color: white !important;
    font-size: 10px;
    
  }
  
  .active {
    background-color: #ffffff !important;
    color: black !important;
  }

@media (max-width: 767px) {
  .form-column {
    flex-basis: 100%;
  }

  .progress-bar {
    padding: 30px;
    height: 50px;
    background-color: lightgray;
    background-color: #ffffff !important;
    margin-right: 5px;
    border-radius: 20px !important;
    color: white !important;
    font-size: 10px;
    
    
  }
  
}
@media (max-width: 1280px) {

  
}
  
  label {
    margin-right: 5px;
  }
  

.btn-card-upload{
  width: 100%;
  height: auto;
  margin: 10px;
  padding: 20px;
  border-radius: 10px;
  background-color: gray !important;
  color: black;
  display: flex;
  justify-content: space-between;
 
}



.indicator img{
  width: 1rem;
}
.indicator-progress img{
  width: 1rem;
}

</style>
